  .about-section {
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      flex-wrap: wrap;
      grid-row: auto;
    }

   .about-info {
    width: 50%;
    text-align: start;
    justify-content: start;
   }

  

   .about-info p {
     text-align: center;
     margin: 10px;
     font-size: 24px;
   }

   .about-info p {
   

    align-items: center;
  }

  
   .picture {
    height: 520px;
   }

  

 .line {
    background-color:#ea9d47;
    height: 6px;
    width: 100%;
    border-radius: 3px;
    margin: 16px 0px;
   
 }

 @media only screen and (max-width: 600px) {
  .picture {
    height: 90%;
    width: 90%;
    margin-left: 20px
   }

   .about-logo{

   }
}