.swiper {
    width: 100%;
    padding-top: 50px;
    padding-bottom: 50px;
  }
  
  .swiper-slide {
    background-position: center;
    background-size: cover;
    
  }

  .carousel {
    display: flex;
    flex-wrap: wrap;
    background-position: center;
    background-size: cover;
    width: 20%;
    height: 20%;
   
  }
  
  .swiper-slide img {
    display: block;
    width: 100%;
    height: 70%;
  }

  .h1 {
    text-align: center;
    font-weight: 300;
    color: #777
  }

  .trait-details {
   
    flex-direction: column;
    margin: 12%
  }

  .trait-details h4 {

  }

  .trait-details h5 {

  }

  .trait-details p {
    font-size: .8em;
  }

  @media only screen and (max-width: 720px) {
    .carousel {
      display: flex;
      flex-wrap: wrap;
      background-position: center;
      background-size: cover;
      width: 60%;
      height: 60%;
     
    }
  }