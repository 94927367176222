.header {
    display: flex;
    padding: 2px;
    position: relative;
    justify-content: space-around;
    align-items: center;
    height: 50px;
}

.menu {
    display: flex;
    justify-content: flex-end;
    margin-left: 30px;
}


.logo {
    display: flex;
}

.logo-picture {
    height: 100px;
 
    padding: 5px;
    margin: 30px;
}

.menu-item {
    display: flex;
    align-items: flex-end;
    font-size: 15px;
    margin-right: 15px;
    font-weight: 700;
}

.menu-item a {
    text-decoration: none;
    background-color: black;
    color: white;
    padding: 1em;
    border: 0;
    border-radius: 20%;

  
}

.social-links {
  margin: 10px;

    
}

.social-links a {


  
    
}

.header-social-icons {
    width: 40px;
    height: 40px;
}
/* Media Queries */

@media only screen and (max-width: 1160px) { 
    .menu-item {
        font-size: 18px;
        margin-right: 10px;
        font-weight: 700;
    }
    
    .menu-item a {
        text-decoration: none;
        background-color: black;
        color: white;
        padding: 1em;
        border: 0;
        border-radius: 20%;
    }

    .header-social-icons {
        width: 35px;
        height: 35px;
    }
}

@media only screen and (max-width: 1024px) { 
    .menu-item {
        font-size: 18px;
        margin-right: 10px;
        font-weight: 700;
    }
    
    .logo-picture {

    }

    .header-social-icons {
        width: 26px;
        height: 26px;
    }
}

@media only screen and (max-width: 720px) {
    .logo {
        display: none;
    }
}

@media only screen and (max-width: 600px) {
    .header {
        
        padding: 2px;
        position: relative;
        align-items: center;
        height: 100%;
        width: 100%
    }
  
    .menu {
        display: flex;
        margin-left: 70px;
    }
    .logo {
        font-size: 24px;
        height: 15px;
        width: 15px;
    }
    .logo-picture {
        height: 10px;
        width: 10px;
        padding: 5px;
        margin: 30px;
    }

    .menu {
        justify-content: center;
    }
    .menu-item {
        font-size: 18px;
        font-weight: 700;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
    }

    .menu-item a{
        text-decoration: none;
        background-color: black;
        color: white;
        padding: .5em;
        border: 0;
        border-radius: 20%;
    }
    .header-social-icons {
        display: none;
    }
}

@media only screen and (max-width: 430px) {
    .menu {
        margin-left: 60px;
    }
    .menu-item {
        font-size: 14px;
        font-weight: 700;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
    }
}

@media only screen and (max-width: 375px) {
    .menu {
        margin-left: 60px;
    }

    .menu-item {
        font-size: 12px;
        font-weight: 700;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
    }
}