.no-kid-container {
    display: flex;
    flex-direction: column;

}

.images {
    display: flex;
    justify-content: center;
    align-items: center;
    
 
}


.collaboration {
    height: 4%;
    width: 4%;
}
.no-kid-hungry {
    height: 20%;
    width: 20%;
}

.mascot-slide4 {
    height: 10%;
    width: 10%;
}

.pledge p {
    font-size: 40px;
}

/* MEDIA QUERIES */


@media only screen and (max-width: 600px) {
    .pledge p {
        font-size: 24px;
    }

    .collaboration {
        height: 10%;
        width: 10%;
    }
    .no-kid-hungry {
        height: 30%;
        width: 30%;
    }
}