

.side1-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 20%;
}

.mascot {
    height: 19%;
    width: 40%;
}

.text-1 {   
    font-size: 35px;
}

.text-2 {
    font-size: 30px;
    
}

.text-3 {
    font-size: 25px;
    
}