html {
    scroll-behavior: smooth;
}

.home {
    width: 100%;
    
}

.header {
    padding: 12px;
    padding-bottom: 36px;
    height: 30vh;

}

@media only screen and (max-width: 1024px){
    .home{
        width: 100%;
    }
}

@media only screen and (max-width: 512px){
    .home{
        width: 100%;
    }
}

@media only screen and (max-width: 380px){
    .home{
        width: 100%;
    }
}

@media only screen and (max-width: 280px){
    .home{
        width: 100%;
    }
}