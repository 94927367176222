.slider3-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    
}
   
.og-head {
    height: 50%;
    width: 50%;
}

.hero-text p {
    font-size: 2em;
}

.hero-text b {
    color: #EA9D47;
    font-size: 1.2em;
 }

.text-1 {   
    font-size: 1.95em;

}

