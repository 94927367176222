
.company-links {
    
    display: flex;
    justify-content: center;
    align-items: center;
  
    
}

.business-social-icons {
    cursor: pointer;
    margin: 12px;
    height: 84px;
    width: 84px;
}


.business-social-icons a {

    display: inline-block;
    transition: .4s;
    margin: 10px;
}

.business-social-icons a:hover {
    transform: scale(1.5)
}

.copyright {
    display: block;
    text-align: center;
    margin: 10px;
    padding: 10px;
    font-size: 10px;
}