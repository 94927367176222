.team-section {
    text-align: center;

}

.team-header {
    padding-top: 60px;
    color: #f2f2f2;
    font-size: 20px;
    margin: auto;
    line-height: 50px;
}

.tm-container {
    max-width: 1200px;
    margin: auto;
    padding: 48px 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.team-member {
    margin: 10px;
    padding: 30px;
    max-width: 30%;
    cursor: pointer;
    transition: 0.4s;
    box-sizing: border-box;
}


.profile {
    width: 200px;
    height: 200px;
    margin: 10px;

    filter: drop-shadow(20px 30px 10px);
}

.name {
    padding: 12px;
    margin: 10px;
    font-weight: bold;
    font-size: 16px;
    text-transform: uppercase;
}

.title {
    font-style: italic;
    margin: 10px;
    color: #545454;
}

.about {
    margin: 20px;
    font-weight: lighter;
}

.social-links a {

    display: inline-block;
    transition: .4s;
    margin: 10px;
}

.social-links a:hover {
    transform: scale(1.5)
}
.social-icons {
    width: 32px;
    height: 32px;
    
}

@media screen and (max-width: 720px) {
    .team-member {
        max-width: 100%;
    }
}